body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html {
  background: #f9f9f9;
}

#root {
  height: 100%;
}

.custom-phone-input .ui.input {
  display: block;
}

:root {
  --PhoneInputCountryFlag-aspectRatio: 1.6666;
}

.luxti-map {
  width: 92%;
  height: 100%;

  min-height: 400px;

  margin-right: 4%;
  margin-left: 4%;
}

.luxti-map div {
  border-radius: 4px;
}

.DatePicker {
  display: block;
}

.ui.pointing.label {
  display: none;
}

.pickup-time {
  height: 28px;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  display: flex;
}

.pickup-time:focus {
  background-color: rgba(34, 36, 38, 0.15);
}

.pickup-time:hover {
  background-color: rgba(34, 36, 38, 0.15);
}

.phone-input-dropdown {
  margin: 2px 1px;
  border: 0px;
}

.ui.form .react-tel-input.error {
  .form-control {
    color: #9f3a38;
    background: #fff6f6;
    border-color: #e0b4b4;
  }
}

.react-tel-input {
  margin: 0px 0px 10px 0px;
  height: 45px;

  &.error {
    .form-control {
      color: #9f3a38;
    }
  }

  .form-control {
    height: 43px;
  }

  .flag-dropdown {
    margin: 2px 1px;
    border: 0px;
    background: transparent;

    .selected-flag {
      padding: 0px 0px 0px 11px;
    }

    .country-list.phone-input-dropdown {
      text-align: left;
    }
  }
}
